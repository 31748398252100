namespace eh {

  export class OVERLAY_EVENTS {
    static readonly EVENT_NODE_CLASS = 'ehts-overlay-events';
    static readonly LAYER_STATE_EVENT: string = 'eh-overlay-on-layer-state';
    static readonly LAYER_STATE_OPENED: string = 'opened';
    static readonly LAYER_STATE_CLOSED: string = 'closed';
  }
  export interface OVERLAY_EVENT_PARAMS {
    state: String;
  }

  export type OverlayContentType = 'video' | 'iframe' | 'iframe-fullw' | 'iframe-scaled' | 'local' | 'local-transp' | 'local-center' | 'raw' | 'local-s2' | 'local-s2w' | 'local-tw';

  export class Overlay implements IMessageHandler {
    public static readonly EventIdPreClose = 'ehOverlay:preClose';
    private static readonly DATA_KEY__LOCAL_REF_ID = 'localRefId';
    private static readonly DATA_KEY__OVERLAY_CONTENT = 'eh.Overlay.content';
  
    private static readonly logger = log.getLogger('eh.Overlay');
    private static instance: Overlay;
    
    public readonly id: string = ('eh-overlay-instance');
    private readonly isMobileSafari: boolean;
    // white bg
    private readonly layer: JQuery<HTMLElement>;
    private readonly layerContent: JQuery<HTMLElement>;
    private layerTitle: JQuery<HTMLElement>;
    private layerClose: JQuery<HTMLElement>;
    private contentCloser: JQuery<HTMLElement> | undefined;
    // transparent bg
    private readonly layerTransp: JQuery<HTMLElement>;
    private readonly layerContentTransp: JQuery<HTMLElement>;
    private layerTitleTransp: JQuery<HTMLElement>;
    private layerCloseTransp: JQuery<HTMLElement>;
    private contentCloserTransp: JQuery<HTMLElement> | undefined;
    // tw
    private readonly layerTw: JQuery<HTMLElement>;
    private readonly layerContentTw: JQuery<HTMLElement>;
    private layerTitleTw: JQuery<HTMLElement>;
    private layerCloseTw: JQuery<HTMLElement>;
    private contentCloserTw: JQuery<HTMLElement> | undefined;

    private previousPage: HTMLElement[] = [];
    private jqXHR?: JQuery.jqXHR;

    static init($base: JQuery<HTMLElement>, isSnippet: boolean): void {
      if(!isSnippet) {
        Overlay.instance = new Overlay($base);
        eh.MessageNexus.setListener(window, Overlay.instance);
      } else {
        this.registerLinks($base);
      }
    }

    static registerLinks($base: JQuery<HTMLElement>): void {
        Overlay.instance?.registerOverlayLinks($base);
    }

    private static notifyLayerState(wrap: JQuery<HTMLElement>, isOpen:boolean) {
      // find .ehts-overlay-events
      // send event
      wrap.selfOrFind(`.${OVERLAY_EVENTS.EVENT_NODE_CLASS}`).trigger(OVERLAY_EVENTS.LAYER_STATE_EVENT, {
        'state': isOpen? OVERLAY_EVENTS.LAYER_STATE_OPENED : OVERLAY_EVENTS.LAYER_STATE_CLOSED
      });
    }

    constructor(private readonly $base: JQuery<HTMLElement>) {
      const userAgent = window.navigator.userAgent;
      this.isMobileSafari = (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) !== null;
      this.registerOverlayLinks(this.$base);
      this.layer = $('.eh-marker-overlay-opaque');
      if (this.isMobileSafari) {
        this.layer.addClass('safari-mobile')
      }
      this.layerContent = this.layer.find('.eh-overlay--content-body');
      this.layerClose = this.layer.find('.eh-overlay--close');
      this.layerTitle = this.layer.find('.eh-overlay--headline');
 
      this.layerTransp = $('.eh-marker-overlay-transp');
      if(this.isMobileSafari) {
        this.layerTransp.addClass('safari-mobile')
      }
      this.layerContentTransp = this.layerTransp.find('.eh-overlay--content-body');
      this.layerCloseTransp = this.layerTransp.find('.eh-overlay--close');
      this.layerTitleTransp = this.layerTransp.find('.eh-overlay--headline');

      // TW 
      this.layerTw = $('.eh-marker-overlay-tw');
      if (this.isMobileSafari) {
        this.layerTw.addClass('safari-mobile');
      }
      this.layerContentTw = this.layerTw.find(
        '.eh-overlay--content-body'
      );
      this.layerCloseTw = this.layerTw.find('.eh-overlay--close');
      this.layerTitleTw = this.layerTw.find('.eh-overlay--headline');

      $('.js-ol-close', this.layerTransp).on('click', (ev) => {
        if ($(ev.target).selfOrClosest('.eh-overlay--content-body, .eh-overlay--close').length === 1) {
          return;
        }
        ev.preventDefault();
        this.closeLayerTransp();
      });
    }

    onMessage(message : Message) {
      if(message.params['$$legacy'] !== undefined) {
        return; // not supported
      }
      if(message.method === '*:open-url') {
        const title = message.params['title'];
        const ref = message.params['url'];
        if(ref) {
          this.showContent(ref, title, 'iframe');  // oder iframe-scaled
        }
      }
      else if(message.method === '*:open-id') {
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContent(ref, title, 'local');  // oder iframe-scaled
        }
      }
      else if(message.method === '*:open-transp-id') {
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContentTransp(ref, title, 'local-transp');  // oder iframe-scaled
        }
      }
      else if(message.method === '*:open-static2-id') { // FLEX
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContentTransp(ref, title, 'local-s2');  
        }
      }
      else if(message.method === '*:open-static2-wide-id') { // FlexDesc
        const title = message.params['title'];
        const ref = message.params['id'];
        if(ref) {
          this.showContentTransp(ref, title, 'local-s2w'); 
        }
      }
    }
  
    private registerOverlayLinks($base: JQuery<HTMLElement>) {
      $('[data-lightbox]:not([data-evict-lightbox=1])', $base).each((_index, el)  => {
        const $link = $(el);
        let type : OverlayContentType = $(el).data('lightbox') || 'iframe';
        // FIXME: if feature on GEC is iframe-scaled and type is iframe ...:
        // type = 'iframe-scaled';
        $link.on('click', (event) => {
          event.preventDefault();
          if(!$link.hasClass('disabled')) {
            const href = $link.attr('href') || '#';
            const title = $link.data('title') || '';
            if(type === 'local-transp') {
              this.showContentTransp(href, title, type);
            } else if (type === 'local-tw') {
              this.showContentTw(href, title, type);
            } else {
                this.showContent(href, title, type);
            }
          }
        });
      });
      $('.js-overlay-close', $base).on('click', (ev) => {
        if ($(ev.target).selfOrClosest('.eh-overlay--content-body, .eh-overlay--close').length !== 1) {
          return;
        }
        ev.preventDefault();
        this.jqXHR?.abort();
        this.closeLayerTransp();
        this.closeLayerTw();
        this.closeLayer();
      });
    }

    private openLayer(forceInit: boolean = false) {
      this.closeLayerTransp(false);
      this.closeLayerTw(false);
      this.layer.show();
      if (this.getContentType() !== 'local' || forceInit) {
        Overlay.notifyReplaced($(this.layer));
      }
      this.layerClose.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayer();
      });
      eh.ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
      Overlay.notifyLayerState(this.layerContent, true);
    }

    private closeLayer(deferred:boolean = true) {
      this.layer.hide();
      this.layerClose.off('click');
      if (this.contentCloser) {
        this.contentCloser.off('click');
      }
      
      const $el = this.layerContent.data(Overlay.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger(jQuery.Event(Overlay.EventIdPreClose));
      eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      if (deferred) {
        setTimeout(() => { // allow the Tracking to access the content before it is removed
          this.putBackLayer();
        }, 0);
      } else {
        this.putBackLayer();
      }
      Overlay.notifyLayerState(this.layerContent, false);
    }

    private putBackLayer() {
      if (['local'].indexOf(this.getContentType()) > -1) {
        const refId = this.layer.data(Overlay.DATA_KEY__LOCAL_REF_ID);
        if (refId) {
          const contentParent = $('#'+refId);
          if (contentParent.length === 1) {
            if(this.layerContent.find('.is-wrapper').length > 0) {
              contentParent.append(this.layerContent.find('.is-wrapper').children());
            } else {
              contentParent.append(this.layerContent.children());
            }
          }
        }
      } else {
          this.layerContent.empty();
      }
    }

    private openLayerTransp(forceInit: boolean = false) {
      this.closeLayer(false);
      this.closeLayerTw(false);
      this.layerTransp.show();
      if (this.getContentType() !== 'local-transp' || forceInit) {
        Overlay.notifyReplaced($(this.layerTransp));
      }
      this.layerCloseTransp.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTransp();
      });
      eh.ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
      Overlay.notifyLayerState(this.layerTransp, true);
    }

    private closeLayerTransp(deferred = true) {
      if(this.layerTransp.find('.eh-scroll-vertical').length === 1) {
        this.layerTransp.find('.eh-scroll-vertical').scrollTop(0);
      }
      if (this.layerTransp.find('.eh-overlay--content-body').length === 1) {
        this.layerTransp.find('.eh-overlay--content-body').scrollTop(0);
      }
      this.layerTransp.hide();
      this.layerCloseTransp.off('click');
      if (this.contentCloserTransp) {
        this.contentCloserTransp.off('click');
      }
      const $el = this.layerContentTransp.data(Overlay.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger(jQuery.Event(Overlay.EventIdPreClose));
      eh.ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      if(deferred) {
        setTimeout(() => { // allow the Tracking to access the content before it is removed
          this.putBackLayerTransp();
        }, 0);
      } else {
        this.putBackLayerTransp();
      }
      Overlay.notifyLayerState(this.layerContentTransp, false);
    }

    private putBackLayerTransp() {
      if (['local-transp', 'local-s2', 'local-s2w'].indexOf(this.getContentType()) > -1) {
        const refId = this.layerTransp.data(Overlay.DATA_KEY__LOCAL_REF_ID);
        if (refId) {
          const $originalParent = $('#' + refId);
          if ($originalParent.length === 1) {
            const $contentParentWrapperToTransferBack = this.layerContentTransp.find('.is-wrapper');
            const $contentParentToTransferBack = $contentParentWrapperToTransferBack.length > 0 ? $contentParentWrapperToTransferBack : this.layerContentTransp;
            $originalParent.append($contentParentToTransferBack.children());
          }
          else {
            Overlay.logger.debug('no single $originalParent found to put back', $originalParent);
          }
        }
      } else {
          this.layerContentTransp.empty();
      }
    }

    private openLayerTw(forceInit: boolean = false, defaultPage: string = '') {
      this.closeLayer(false);
      this.closeLayerTransp(false);
      this.layerTw.show();
      if (this.getContentType() !== 'local-tw' || forceInit) {
        Overlay.notifyReplaced($(this.layerTw));
      }
      this.layerCloseTw.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTw();
      });

      this.layerContentTw.find('.eh-overlay-back').each((_ind, backEl) => {
        backEl.classList.add('ehtw-hidden');
      });

      this.layerContentTw.find('.eh-overlay-page').each((index, el) => {
        el.style.transition = 'none';
        if (el.parentElement) {
          el.parentElement.style.transition = 'none';
        }
        if ((!defaultPage && index > 0) || (defaultPage && defaultPage != el.id)) {
          el.style.left = '100%';
        } else {
          el.style.left = '0%';
          if (el.parentElement) {
            el.style.height = '';
            el.parentElement.style.height = (el.clientHeight + 48) + 'px';
            el.style.height = 'calc(100% - 48px)'
          }
          this.previousPage.push(el);
        }

        setTimeout(() => {
          el.style.transition = '';
          if (el.parentElement) {
            el.parentElement.style.transition = '';
          }
          Overlay.notifyLayerState(this.layerContentTw, true);
        }, 500);
      });

      // Probar con transform 
      this.layerContentTw.find('.eh-overlay-back').each((_index, el) => {
        el.addEventListener('click', (ev) => {
          ev.preventDefault();
          const last = this.previousPage[this.previousPage.length - 1];
          const previous = this.previousPage[this.previousPage.length - 2];
          this.previousPage.pop();

          if (this.previousPage.length > 1) {
            el.classList.remove('ehtw-hidden');
          } else {
            el.classList.add('ehtw-hidden');
          }
          previous.style.left = '0%';
          if (previous.parentElement) {
            previous.style.height = '';
            previous.parentElement.style.height = (previous.clientHeight + 48) + 'px';
            previous.style.height = 'calc(100% - 48px)'
          }
          last.style.left = '100%';
        });
      });

      this.layerContentTw.find('.eh-overlay-link-page').each((_index, el) => {
          el.addEventListener('click', (ev) => {
            ev.preventDefault();
            const href = el.getAttribute('href');
            this.layerContentTw.find('.eh-overlay-page').each((_index, page) => {
              if (`#${page.id}`=== href ) {
                page.style.left = '0';
                if (page.parentElement) {
                  page.style.height = '';
                  page.parentElement.style.height = (page.clientHeight + 48) + 'px';
                  page.style.height = 'calc(100% - 48px)';
                }
                this.previousPage.push(page);
                this.layerContentTw.find('.eh-overlay-back').each((_ind, backEl) => {
                  backEl.classList.remove('ehtw-hidden');
                });
              } else {
                page.style.left = '-100%';
              }
            });
          })
      });
      ScrollPage.setScrollEnabled(false, 'eh-no-scroll eh-full-height');
    }
  
    private closeLayerTw(deferred = true) {
      this.jqXHR?.abort();
      if (this.layerTw.find('.eh-scroll-vertical').length === 1) {
        this.layerTw.find('.eh-scroll-vertical').scrollTop(0);
      }
      if (this.layerTw.find('.eh-overlay--content-body').length === 1) {
        this.layerTw.find('.eh-overlay--content-body').scrollTop(0);
      }
      this.layerTw.hide();
      this.layerCloseTw.off('click');
      if (this.contentCloserTw) {
        this.contentCloserTw.off('click');
      }
      const $el = this.layerContentTw.data(Overlay.DATA_KEY__OVERLAY_CONTENT);
      $($el).trigger($.Event(Overlay.EventIdPreClose));
      ScrollPage.setScrollEnabled(true, 'eh-no-scroll eh-full-height');
      if (deferred) {
        setTimeout(() => {
          // allow the Tracking to access the content before it is removed
          this.putBackLayerTw();
        }, 0);
      } else {
        this.putBackLayerTw();
      }
      Overlay.notifyLayerState(this.layerContentTw, false);
    }
  
    private putBackLayerTw() {
      if (['local-tw'].indexOf(this.getContentType()) > -1) {
        const refId = this.layerTw.data(Overlay.DATA_KEY__LOCAL_REF_ID);
        if (refId) {
          const $originalParent = $('#' + refId);
          if ($originalParent.length === 1) {
            const $contentParentWrapperToTransferBack = this.layerContentTw.find('.is-wrapper');
            const $contentParentToTransferBack = $contentParentWrapperToTransferBack.length > 0
                ? $contentParentWrapperToTransferBack
                : this.layerContentTw;
            $originalParent.append($contentParentToTransferBack.children());
          } else {
            Overlay.logger.debug('no single $originalParent found to put back (tw)', $originalParent);
          }
        } else {
          this.layerContentTw.empty();
        }
      }
    }

    /* dumb show */
    public putContent(content: JQuery<HTMLElement>, title?: string) {
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);

      if (this.contentCloser) {
        this.contentCloser.off('click');
      }
      this.layerContent.empty();
      
      this.layerContent.data(Overlay.DATA_KEY__OVERLAY_CONTENT, content);
      this.layerContent.append(eh.Overlay.createContentWrapper().append(content));
      this.layerTitle.empty();
      if (title) {
        this.layerTitle.html(title);
      }
      this.setContentType('raw');
      this.contentCloser = $('.js-overlay-close', this.layerContent);
      this.contentCloser.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayer();
      });
      
      this.openLayer();
    }

    private showContent(href: string, title: string, type: OverlayContentType) {
      const ref = this.analyzeRef(href);
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);

      if (this.contentCloser) {
        this.contentCloser.off('click');
      }
      this.layerContent.empty();
      
      const innerContent = this.getTemplate(type, ref);
      this.layerContent.data(Overlay.DATA_KEY__OVERLAY_CONTENT, innerContent.children());
      this.layerContent.append(innerContent);
      this.setContentType(type);
      this.layerTitle.empty();
      this.layerTitle.html(title);
      this.contentCloser = $('.js-overlay-close', this.layerContent);
      this.contentCloser.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayer();
      });
      this.openLayer();
    }

    private showContentTransp(href: string, title: string, type: OverlayContentType) {
      const ref = this.analyzeRef(href);
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);

      if (this.layerContentTransp.closest('.eh-scroll-vertical').length === 1) {
        this.layerContentTransp.closest('.eh-scroll-vertical')[0].scrollTop = 0;
      }
      if (this.contentCloserTransp) {
        this.contentCloserTransp.off('click');
      }
      this.layerContentTransp.empty();
      
      const innerContent = this.getTemplate(type, ref).children();
      this.layerContentTransp.data(Overlay.DATA_KEY__OVERLAY_CONTENT, innerContent);
      this.layerContentTransp.append(innerContent);
      this.setContentType(type);
      this.layerTitleTransp.empty();
      this.layerTitleTransp.html(title);
      this.contentCloserTransp = $('.js-overlay-close', this.layerContentTransp);
      this.contentCloserTransp.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTransp(true);
      });
      this.openLayerTransp();
    }
    
    private showContentTw(href: string, title: string, type: OverlayContentType) {
      const ref = this.analyzeRef(href);
      this.closeLayerTw(false);
      this.closeLayerTransp(false);
      this.closeLayer(false);
  
      if (this.contentCloserTw) {
        this.contentCloserTw.off('click');
      }
      this.layerContentTw.empty();
      this.previousPage = [];
      
      const innerContent = this.getTemplate(type, ref);
      this.layerContentTw.data(
        Overlay.DATA_KEY__OVERLAY_CONTENT,
        innerContent.children()
      );
      this.layerContentTw.append(innerContent);
      this.setContentType(type);
      this.layerTitleTw.empty();
      this.layerTitleTw.html(title);
      this.contentCloserTw = $('.js-overlay-close', this.layerContentTw);
      this.contentCloserTw.on('click', (ev) => {
        ev.preventDefault();
        this.closeLayerTw();
      });
      
      this.openLayerTw(false, ref.type === 'id' ? ref.additionalId ?? '' : '');
    }

    private analyzeRef(ref: string): OverlayContentSource {
      // ref might be: #id-1#id-2 | id-1 | /url | https://url
      if (ref.search(new RegExp('^(/)|(https?://)')) > -1) {
        return {type: 'url', url: ref};
      }
      const parts = ref.split('#');
      if (parts.length === 1) {
        return {type: 'id', id: parts[0]};
      }
      return {type: 'id', id: parts[1], additionalId: parts[2]};
    }

    private getTemplate(type: OverlayContentType, ref: OverlayContentSource): JQuery<HTMLElement> {
      if (ref.type === 'url') {
        switch (type) {
          case 'video': {
            return $(`<div class="eh-player"><video class="eh-image eh-layout--display-block eh-full-width" autoplay><source type="video/mp4" src="${ref.url}"/></video></div>`);
          }
          case 'iframe-fullw':
          case 'iframe': {
            return $(`<iframe class="eh-overlay--content-frame eh-no-b eh-full-width eh-full-height" src="${ref.url}" allow="fullscreen; clipboard-write;"></iframe>`);
          }
          case 'iframe-scaled': {
            return $(`<iframe class="eh-overlay--content-frame eh-no-b eh-full-width eh-full-height eh-iframe-scaled eh-iframe-scale-height" src="${ref.url}" allow="fullscreen; clipboard-write;" data-original-width="1024"></iframe>`);
          }
          case 'local-tw': {
            const wrapper = eh.Overlay.createCenterWrapper();
            this.jqXHR = $.ajax({
              'url': ref.url,
              'dataType': 'html',
              'method': 'get'
            });
            this.jqXHR.then(
              (data: any) => {
                console.log('overlay ajax data', data);
                wrapper.empty().append($(data).children());
                Overlay.notifyReplaced(wrapper);
              }, 
              (_jqXHR, textStatus, errorThrown) => {
                console.warn('overlay ajax error', textStatus, errorThrown);
              }
            );
            return wrapper;
          }
          default:
            console.warn('overlay type ref mismatch', type, ref);
            return $('');
        }
      }
      else {
        switch (type) {
          case 'local': {
            let elId = ref.id;
            this.layer.data(Overlay.DATA_KEY__LOCAL_REF_ID, elId);
            const wrapper = eh.Overlay.createContentWrapper();
            return wrapper.append($('#' + elId).children());
          }
          case 'local-s2':
          case 'local-s2w':
          case 'local-transp': {
            let elId = ref.id;
            this.layerTransp.data(Overlay.DATA_KEY__LOCAL_REF_ID, elId);
            const wrapper = eh.Overlay.createCenterWrapper();
            return wrapper.append($('#' + elId).children());
          }
          case 'local-tw': {
            let elId = ref.id;
            this.layerTw.data(Overlay.DATA_KEY__LOCAL_REF_ID, elId);
            const wrapper = eh.Overlay.createCenterWrapper();
            return wrapper.append($('#' + elId).children());
          }
          default:
            console.warn('overlay type-ref mismatch', type, ref);
            return $('');
        }
      }
    }

    private getContentType(): OverlayContentType {
      return this.layer.data('contentType');
    }

    private setContentType(type: OverlayContentType) {
      switch (type) {
        case 'iframe-fullw': {
          // iframe-fullw
          let box = $('.eh-center-page--large', this.layer);
          box.removeClass('eh-center-page--large').addClass('eh-center-page--large-off');
          break;
        }
        case 'local-s2w':
          // local-s2* white bg
          let box = $('.eh-overlay--content.eh-block-theme--10', this.layerTransp);
          box.removeClass('eh-block-theme--10').addClass('eh-block-theme--14');
          // local-s2w wide
          box = $('.eh-lightbox--static', this.layerTransp);
          box.removeClass('eh-lightbox--static').addClass('eh-lightbox--static-2').addClass("has-indent");
          break;
      case 'local-s2': {
          // local-s2* white bg
          let box = $('.eh-overlay--content.eh-block-theme--10', this.layerTransp);
          box.removeClass('eh-block-theme--10').addClass('eh-block-theme--14');
          // reset 
          box = $('.eh-lightbox--static-2', this.layerTransp);
          box.removeClass('eh-lightbox--static-2').removeClass('has-indent').addClass('eh-lightbox--static');
          break;
        }
        default: {
          // reset all
          let box = $('.eh-center-page--large-off', this.layer);
          box.removeClass('eh-center-page--large-off').addClass('eh-center-page--large');
          box = $('.eh-overlay--content.eh-block-theme--14', this.layerTransp);
          box.removeClass('eh-block-theme--14').addClass('eh-block-theme--10');
          box = $('.eh-lightbox--static-2', this.layerTransp);
          box.removeClass('eh-lightbox--static-2').removeClass('has-indent').addClass('eh-lightbox--static');
        }
      }
      return this.layer.data('contentType', type);
    }

    private static notifyReplaced(el: JQuery<HTMLElement>) {
      const event = jQuery.Event(cs.Snippet.EventIdPostReplace) as cs.SnippetEventPostReplace;
      event.replacedTarget = el;
      $(':root').trigger(event);
    }

    private static createContentWrapper() {
      return $('<div class="eh-layout--abs-expand is-wrapper"></div>');
    }
    private static createCenterWrapper() {
      return $('<div class="is-wrapper"></div>');
    }
  }
  
  type OverlayContentSource = {
    type: 'url';
    url: string;
  } | {
    type: 'id';
    id: string;
    additionalId?: string;
  };
  
}

