import offsetTopToBody = eh.offsetTopToBody;

namespace eh {

    export interface IHeaderMetrics {
        visibleHeaderHeight: number;
        visibleDisplaceElemHeight: number;
        headerStickyState: boolean
    }

    export class HEADER_EVENTS {
        static DOM_MUTATION: string = 'eh-headercomposer-on-dom-mutation';
    }

    export class Header {
        static HEADER_DISPLACE_ELEMENT_CLASS: string = '.eh-tabs--products-sticky';
        static HEADER_DISPLACE_ACTIVE: boolean = true;

        static init($base: JQuery<HTMLElement>, isSnippetRequest: boolean = false ): void {
            if (isSnippetRequest) {
                return;
            }
            let headerComposer: HeaderComposition;
            const displacingElement: JQuery<HTMLElement> = $(Header.HEADER_DISPLACE_ELEMENT_CLASS, $base);
            const stickyMobileHeader: HeaderComposition = new HeaderComposition(new HeaderMobileScrollBehavior(displacingElement.get(0)));
            const stickyDesktopHeader: HeaderComposition = new HeaderComposition(new HeaderDesktopScrollBehavior(displacingElement.get(0)));

            const breakpointManager: Breakpoints = eh.Breakpoints.getInstance();
            breakpointManager.registerViewTypeListener(onViewTypeChanged);
            breakpointManager.registerResizeListener(onResize);
            eh.MarketSelector.uiStateSupport.registerUIStateListener(onMarketHeaderChanged);

            function onViewTypeChanged(viewType: BreakPointViewType): void {
                const composition: HeaderComposition = viewType === eh.BreakPointViewType.MOBILE ? stickyMobileHeader: stickyDesktopHeader;
                headerComposer?.dispose();
                headerComposer = composition;
                headerComposer.init();
            }

            function onResize(): void {
                headerComposer.resize();
            }

            function onMarketHeaderChanged() {
              headerComposer.resize();
            }
        }
    }

    export class HeaderComposition {
        private _resizeUpdateDelay: number = 200;
        private _resizeTimeout: number;

        constructor (private _behavior: any) {}

        dispose(): void {
            clearTimeout(this._resizeTimeout);
            this._behavior.destroy();
        }

        init(): void {
            this._behavior.init();
        }

        resize(): void {
            window.clearTimeout(this._resizeTimeout);
            this._resizeTimeout = window.setTimeout(this._resizeNow, this._resizeUpdateDelay, this);
        }

        private _resizeNow: () => void = (): void => {
            this._behavior.init();
        }
    }
}

class HeaderMobileScrollBehavior {
    private static HEADER_DISPLACEMENT_ACTIVE: boolean = eh.Header.HEADER_DISPLACE_ACTIVE;

    private headerStickyState: boolean = false;
    private readonly headerWrapper: HTMLElement;
    private readonly visibleRowToKeepInViewport: HTMLElement;
    private readonly page: HTMLElement;
    private readonly marketChooser: HTMLElement;
    private scroller: Document;
    private stickyClass: string = 'is-sticky';
    private readonly stickyParentClass: string = 'is-sticky-parent';
    private headerCollapsedHeight: number;
    private headerInitialHeight: number;
    private headerStickyThreshold: number;
    private ticking: boolean = false;
    private displaceThreshold: number = NaN;
    private readonly wrapElement!: HTMLElement;
    private readonly stickyParentElement!: HTMLElement;


    private isScrollingDown = true;
    private lastScrollTop = 0;
    private startScrollTop = 0;
    private offsetScrollingUp = 0;
  
    private readonly stickyOffset: number = 300; 
    private readonly togetherOffset: number = 100; 
    private readonly displaceOffset: number = 0; 
  
    constructor (private readonly displaceByElement: HTMLElement | undefined) {
        this.headerWrapper = document.querySelector('.eh-header--wrapper') as HTMLElement;
        this.stickyParentElement = document.querySelector(`.${this.stickyParentClass}`) as HTMLElement;
        if (this.displaceByElement?.dataset['stickyWrapClass']) {
          this.wrapElement = document.querySelector(`.${this.displaceByElement?.dataset['stickyWrapClass']}`) as HTMLElement;
        }
        this.marketChooser = document.querySelector('.ehel-market-chooser-banner') as HTMLElement;
        this.visibleRowToKeepInViewport = document.querySelector('.eh-mobile-navigation--search-row') as HTMLElement;
        this.scroller = document as Document;
        this.page = document.querySelector('#eh-page') as HTMLElement;
        this.registerFilterButtonStickyChange();
      
        if (!this.headerWrapper || !this.visibleRowToKeepInViewport || !this.page) {
            //throw new Error('Failed to query required elements.');
            return;
        }
    }

    public init(): void {
        this.destroy();
        this.initializeHeaderScroll();
        const eventParams: any = eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
        this.scroller.addEventListener('scroll', this.onScrollChange, eventParams);
    }

    public destroy(): void {
        this.scroller.removeEventListener('scroll', this.onScrollChange);
        this.headerWrapper.classList.remove(this.stickyClass);
        // @TODO: removing attr style is a dangerous operation
        this.headerWrapper.removeAttribute('style');
        this.page.removeAttribute('style');
        if (this.displaceByElement) {
            this.displaceByElement.classList.remove(this.stickyClass);
            if(this.stickyParentElement) {
              this.stickyParentElement.style.minHeight = 'unset';
            }
        }
    }

    private registerFilterButtonStickyChange(): void {
        $(':root').on(eh.FILTER_BUTTON_EVENTS.STICKY_CHANGE, (_e, isSticky: boolean) => {
            if (this.displaceByElement) {
                if (!isSticky) {
                    this.displaceByElement.classList.add('has-shadow');
                } else {
                    this.displaceByElement.classList.remove('has-shadow');
                }
            } else {
                if (!isSticky) {
                    this.headerWrapper.classList.add('has-shadow');
                } else {
                    this.headerWrapper.classList.remove('has-shadow');
                }
            }
        });
    }

    private initializeHeaderScroll(): void {
      if (this.displaceByElement) {
          this.displaceThreshold = offsetTopToBody(this.displaceByElement);
      }
      this.headerStickyState = false;
      this.headerInitialHeight = this.headerWrapper.offsetHeight;
      this.headerCollapsedHeight = this.visibleRowToKeepInViewport.offsetHeight;
      this.headerStickyThreshold = this.headerInitialHeight - this.headerCollapsedHeight;
      this.offsetScrollingUp = window.innerHeight * 0.18;
      this.onScrollChange();
    }

    private onScrollChange: () => void = (): void => {
      if ((eh.ScrollPage.getScrollRoot().scrollTop() || 0) - this.lastScrollTop > 0) {
        this.isScrollingDown = true;
        this.startScrollTop = eh.ScrollPage.getScrollRoot().scrollTop() || 0;
      } else {
        if (this.offsetScrollingUp < (this.startScrollTop - (eh.ScrollPage.getScrollRoot().scrollTop() || 0))) {
          this.isScrollingDown = false;
        }
      }
      this.lastScrollTop = eh.ScrollPage.getScrollRoot().scrollTop() || 0;

      if (!this.ticking) {
          window.requestAnimationFrame(() => {
              this.restrict();
              this.ticking = false;
          });
          this.ticking = true;
      }
    };

    private restrict(): void {
      const offset: number = eh.ScrollPage.getScrollRoot().scrollTop() || 0;
      const bannerHeight = this.marketChooser?.offsetHeight || 0;
      //this.headerWrapper.style.top = bannerHeight + 'px';
      let headerTop = bannerHeight + 'px';
      let innerHeight = this.headerWrapper.querySelector('.eh-mobile-navigation-links')?.clientHeight || this.headerWrapper.offsetHeight;

      // enable repositioning
      if ( !this.displaceByElement || HeaderMobileScrollBehavior.HEADER_DISPLACEMENT_ACTIVE) {
        if (offset > this.stickyOffset) {
          if (!this.headerStickyState) {
            this.headerWrapper.classList.add(this.stickyClass);
            this.headerStickyState = true;
          }
        } else {
          if (this.headerStickyState) {
            this.headerWrapper.classList.remove(this.stickyClass);
            headerTop = bannerHeight + 'px';
            this.headerStickyState = false;
          }
        }
      }
      let activeDisplacement: number = 0;
      if (this.displaceByElement) {
        const offsetDiff = offset - this.displaceThreshold;

        if (offset >= this.displaceThreshold + this.displaceOffset) {
          if (this.stickyParentElement && this.stickyParentElement.style.minHeight === 'unset') {
            this.stickyParentElement.style.minHeight = `${this.displaceByElement.clientHeight}px`;
          }
          this.displaceByElement.classList.add(this.stickyClass);
          this.displaceByElement.classList.add('has-shadow');
          this.headerWrapper.classList.remove('ehtw-shadow-fly');
          this.headerWrapper.classList.remove('has-shadow');
  
          if (this.isScrollingDown && offsetDiff > this.togetherOffset + this.displaceOffset) {
            if (this.displaceByElement.dataset['stickyStack']) {
              this.displaceByElement.style.top = bannerHeight + innerHeight - 1 + 'px';
            } else {
              headerTop = `-${innerHeight}px`;
              this.displaceByElement.style.top =  bannerHeight - 1 + 'px';
            }
          } else {
            if (this.displaceByElement.style.top ===  bannerHeight - 1 + 'px') {
              this.displaceByElement.style.opacity = '1';
              this.displaceByElement.style.top = bannerHeight + innerHeight - 1 + 'px';
              headerTop = bannerHeight - 1 + 'px';
            }
            //visibleHeaderHeight = this.displaceByElement.offsetHeight;
          }
        } else {
          this.displaceByElement.style.opacity = '1';
          this.displaceByElement.style.top =  bannerHeight - 1 + 'px';
          this.displaceByElement.classList.remove(this.stickyClass);
          if (!this.headerStickyState && this.stickyParentElement) {
            this.stickyParentElement.style.minHeight = 'unset';
          }
          this.displaceByElement.classList.remove('has-shadow');
          this.headerWrapper.classList.add('ehtw-shadow-fly');
          this.headerWrapper.classList.add('has-shadow');
        }
        if (offsetDiff > 0 && offsetDiff < this.displaceOffset) {
          this.displaceByElement.style.opacity = '0';
        }
        if (this.wrapElement && this.wrapElement.offsetHeight < offset) {
          this.displaceByElement.style.top = '-1px';
        }
      } else {
        this.headerWrapper.classList.add('ehtw-shadow-fly');
        this.headerWrapper.classList.add('has-shadow');
      }
  
      let visibleHeaderHeight = this.headerWrapper.offsetHeight + bannerHeight;
      let visibledisplaceElemHeight = 0;
      if(this.displaceByElement && $(this.displaceByElement).hasClass(this.stickyClass)) {
        visibledisplaceElemHeight = this.displaceByElement.offsetHeight;
      }
      $(':root').trigger(eh.HEADER_EVENTS.DOM_MUTATION, {
        //visibleHeaderHeight: visibleHeaderHeight,
        visibleHeaderHeight: visibleHeaderHeight,
        visibleDisplaceElemHeight: visibledisplaceElemHeight,
        headerStickyState: this.headerStickyState
      });
      this.headerWrapper.style.top = headerTop;
    }
  }

class HeaderDesktopScrollBehavior {
    private static HEADER_DISPLACEMENT_ACTIVE: boolean = eh.Header.HEADER_DISPLACE_ACTIVE;

    private headerStickyState: boolean = false;
    private readonly headerWrapper: HTMLElement;
    private readonly scroller: Document;
    private readonly page: HTMLElement;
    private readonly marketChooser: HTMLElement;
    private readonly stickyClass: string = 'is-sticky';
    private readonly stickyParentClass: string = 'is-sticky-parent';
    private readonly tableOfContent: string = 'ehel-anchor-navigation-table-of-content';
    private headerCollapsedHeight: number = 64; // 48 height + (top: 8, bottom: 8)
    private headerInitialHeight: number;
    private elementUpdateThresholdMin: number;
    private elementUpdateThresholdMax: number;
    private ticking: boolean = false;
    private displaceThreshold: number = NaN;

    private readonly wrapElement!: HTMLElement;
    private readonly stickyParentElement!: HTMLElement;
    // 2.9.8
    private readonly stickyOffset: number = 300; 
    private readonly togetherOffset: number = 100; 
    private readonly displaceOffset: number = 0; 
    private isScrollingDown = true;
    private lastScrollTop = 0;
    private startScrollTop = 0;
    private offsetScrollingUp = 0;
    private timeoutId = 0;

    constructor (private readonly displaceByElement: HTMLElement | undefined) {
      if (this.displaceByElement && this.displaceByElement.classList.contains(this.tableOfContent)) {
        this.displaceByElement = undefined;
      }
      if (this.displaceByElement?.dataset['stickyWrapClass']) {
        this.wrapElement = document.querySelector(`.${this.displaceByElement?.dataset['stickyWrapClass']}`) as HTMLElement;
      }
      this.headerWrapper = document.querySelector('.eh-header--wrapper') as HTMLElement;
      this.stickyParentElement = document.querySelector(`.${this.stickyParentClass}`) as HTMLElement;
      this.marketChooser = document.querySelector('.ehel-market-chooser-banner') as HTMLElement;
      this.scroller = document as Document;
      this.page = document.querySelector('#eh-page') as HTMLElement;
      if (!this.headerWrapper || !this.page || !this.scroller) {
          //throw new Error('Failed to query required elements.');
          return;
      }
    }

    public init(): void {
      this.destroy();
      this.initializeHeaderScroll();
      const eventParams: any = eh.Modernizr?.passiveeventlisteners ? { passive: true } : {};
      this.scroller.addEventListener('scroll', this.onScrollChange, eventParams);
    }

    public destroy(): void {
      this.scroller.removeEventListener('scroll', this.onScrollChange);
      this.headerWrapper.classList.remove(this.stickyClass);
      // @TODO: removing attr style is a dangerous operation
      this.headerWrapper.removeAttribute('style');
      this.page.removeAttribute('style');
      if (this.displaceByElement) {
        this.displaceByElement.classList.remove(this.stickyClass);
        if(this.stickyParentElement) {
          this.stickyParentElement.style.minHeight = 'unset';
        }
        this.displaceByElement.classList.remove('has-shadow');
      }
    }


    private initializeHeaderScroll(): void {
        if (this.displaceByElement) {
            this.displaceThreshold = offsetTopToBody(this.displaceByElement);
        }
        this.headerStickyState = false;
        this.headerInitialHeight = Math.min(this.headerWrapper.offsetHeight, 110);
        this.elementUpdateThresholdMin = this.headerInitialHeight;
        this.elementUpdateThresholdMax = this.headerInitialHeight + this.headerCollapsedHeight;
        this.offsetScrollingUp = window.innerHeight * 0.18;
        this.onScrollChange();
    }

    private onScrollChange: () => void = (): void => {
      if ((eh.ScrollPage.getScrollRoot().scrollTop() || 0) - this.lastScrollTop > 0) {
        this.isScrollingDown = true;
        this.startScrollTop = eh.ScrollPage.getScrollRoot().scrollTop() || 0;
      } else {
        if (this.offsetScrollingUp < (this.startScrollTop - (eh.ScrollPage.getScrollRoot().scrollTop() || 0))) {
          this.isScrollingDown = false;
        }
      }
      this.lastScrollTop = eh.ScrollPage.getScrollRoot().scrollTop() || 0;
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.restrict();
          this.ticking = false;
        });
        this.ticking = true;
      }
    };

    private restrict(): void {
      const offset: number = eh.ScrollPage.getScrollRoot().scrollTop() || 0;
      const bannerHeight = this.marketChooser?.offsetHeight || 0;

      let slideInPos: number = 0;
      let safetyGap: number = 0;
      let visibleHeaderHeight: number = 0;
      let headerTop = this.headerWrapper.style.top;
      let innerHeight = this.headerWrapper.querySelector('.ehel-navigation--bottom')?.clientHeight || this.headerWrapper.offsetHeight;

      const isSearchActive = document.body.classList.contains('eh-header-search-bar--has-focus');

      if (!isSearchActive) {
        if (!this.displaceByElement || HeaderDesktopScrollBehavior.HEADER_DISPLACEMENT_ACTIVE) {
            const safetyThresholdMin: number = this.headerInitialHeight - this.headerCollapsedHeight;
            if (offset >= safetyThresholdMin && this.elementUpdateThresholdMax) {
                safetyGap = Math.min(offset - safetyThresholdMin, this.headerCollapsedHeight);
            }

            if (offset > this.elementUpdateThresholdMax && this.headerStickyState) {
              headerTop = bannerHeight + 'px';
            }
            // enable repositioning
            if (offset > this.stickyOffset) {
                if (!this.headerStickyState) {
                    this.headerWrapper.classList.add(this.stickyClass);
                    //this.page.style.paddingTop = this.headerInitialHeight + 'px';
                    this.headerStickyState = true;
                }
            } else {
                if (this.headerStickyState) {
                    this.headerWrapper.classList.remove(this.stickyClass);
                    //this.page.style.paddingTop = '0';
                    this.headerStickyState = false;
                }
            }

            // transform offsets based on pixels
            if (offset < this.elementUpdateThresholdMax && offset > this.elementUpdateThresholdMin && this.headerStickyState) {
                const slideInRatio: number = (offset - this.elementUpdateThresholdMin) / this.headerCollapsedHeight;
                slideInPos = -this.headerCollapsedHeight * (1 - slideInRatio);
                //this.headerWrapper.style.transform = 'translate3d(0, ' + slideInPos + 'px, 0)';
            } else {
              headerTop = bannerHeight + 'px';
            }
        }

        if (this.displaceByElement) {
          const offsetDiff = offset - this.displaceThreshold;
          if (offset >= this.displaceThreshold + this.displaceOffset && this.headerStickyState) {
            if (this.stickyParentElement && this.stickyParentElement.style.minHeight === 'unset') {
              this.stickyParentElement.style.minHeight = `${this.displaceByElement.clientHeight}px`;
            }
            this.displaceByElement.classList.add(this.stickyClass);
            this.displaceByElement.classList.add('has-shadow');
            this.headerWrapper.classList.remove('ehtw-shadow-fly');
            this.headerWrapper.classList.remove('has-shadow');

            if (this.isScrollingDown && offsetDiff > this.togetherOffset + this.displaceOffset) {
              if (this.displaceByElement.dataset['stickyStack']) {
                this.displaceByElement.style.top = (innerHeight + bannerHeight)+ 'px';
              } else {
                headerTop = `-${innerHeight}px`;
                this.displaceByElement.style.top = bannerHeight + 'px';
              }
            } else {
              if (this.displaceByElement.style.top === bannerHeight + 'px') {
                this.displaceByElement.style.opacity = '1';
                this.displaceByElement.style.top = (innerHeight + bannerHeight)+ 'px';
              }
              visibleHeaderHeight = this.displaceByElement.offsetHeight;
            }
          } else {
            this.displaceByElement.style.opacity = '1';
            this.displaceByElement.style.top = bannerHeight + 'px';
            this.displaceByElement.classList.remove(this.stickyClass);
            if(this.stickyParentElement && this.stickyParentElement.style) {
              this.stickyParentElement.style.minHeight = 'unset';
            }
            this.displaceByElement.classList.remove('has-shadow');
            this.headerWrapper.classList.add('ehtw-shadow-fly');
            this.headerWrapper.classList.add('has-shadow');
          }
          if (offsetDiff > 0 && offsetDiff < this.displaceOffset) {
            this.displaceByElement.style.opacity = '0';
          }
          if (this.wrapElement && this.wrapElement.offsetHeight < offset) {
            this.displaceByElement.style.top = '0px';
          }
        } else {
          this.headerWrapper.classList.add('ehtw-shadow-fly');
          this.headerWrapper.classList.add('has-shadow');
        }
        if(this.timeoutId) {
          window.clearTimeout(this.timeoutId);
        }
        this.timeoutId = window.setTimeout(()=> this.triggerChange(), 200);
        this.headerWrapper.style.top = headerTop;
      }
    }

    private triggerChange(): void {
      const bannerHeight = this.marketChooser?.offsetHeight || 0;
      const offset: number = eh.ScrollPage.getScrollRoot().scrollTop() || 0;
      let visibleHeaderHeight = Math.max(0, Math.min(0, this.headerWrapper.offsetTop) + this.headerWrapper.offsetHeight);
      let visibledisplaceElemHeight = 0;
      if (this.displaceByElement && $(this.displaceByElement).hasClass(this.stickyClass)) {
        visibledisplaceElemHeight = this.displaceByElement.offsetHeight;
      }
      if (offset == 0) {
        visibleHeaderHeight = Math.max(this.headerInitialHeight, visibleHeaderHeight) + bannerHeight;
      }
      $(':root').trigger(eh.HEADER_EVENTS.DOM_MUTATION, {
          //visibleHeaderHeight: visibleHeaderHeight,
          visibleHeaderHeight: visibleHeaderHeight,
          visibleDisplaceElemHeight: visibledisplaceElemHeight,
          headerStickyState: this.headerStickyState
      });
    }
}
